import styled from "styled-components";

export const WrapperTiServies = styled.div`
  .ti-services {
    padding-bottom: 0%;
    position: relative;
    &-grid-container{
      margin-bottom: 2rem;
    }

    &__container {
      flex-direction: column;
      display: flex;
      &-img {
        position: absolute;
        left: 0;
        width: 20%;
        @media (max-width: 768px) {
          width: 80%;
        }
      }
      &-subtitle {
        text-align: left;
        margin-top: 5%;
        font: normal normal 600 14px/30px Montserrat;
        color: #7142b1;
      }
      
      &__title {
        margin-bottom: 10%;
        display: flex;
        flex-direction: column;
        align-items: center;
        &-text {
          text-align: left;
          font: normal normal bold 37px/45px Montserrat;
         
        }
      }
      &__grid{
            display: flex;
            align-items: center;
            flex-direction: column;
            &__items{
              width: 100%;
              &-img{
                width: 100%;
                height: 20rem;
              }
            }
            
          }
    }
  }
`;
