import styled from "styled-components";

export const WrappeAboutUs = styled.div`
  .about-us__container {
    &__img1 {
      width: 28%;
      position: absolute;
      left: 0;
      align-items: center;
      align-content: center;
    }
    &__img {
      display: flex;
      width: 80%;
      justify-content: center;
      margin-top: 40px;
      margin-bottom: 40px;
    }
    &.middleTop-worker-text {
      font: "Montserrat";
      font-weight: bold;
      font-size: 37px;
    }

    .middleTop-worker-text {
      font-size: 25px;
    }
    &-middleBot-worker-text{
      font-size: 18px;
    }
  }
  @media (max-width: 768px) {
    .about-us__container {
      &__img1 {
        width: 20%;
      }
      &__img2 {
        display: flex;
        width: 100%;
        justify-content: center;
        margin-top: 20px;
        align-items: center;
      align-content: center;
       
      }
    }
  }
`;
