import React from "react";
import "./style.css";
import { PyselLogo } from "../../svg/PyselLogo";
import { Container } from "@mui/material";

export const Footer = () => {
  return (
      <div className="main-footer" >
      <div className="main-footer-style">
       {/*  <div className="footer-title-style">
          <p>INICIO</p>
          <div className="footer-list-style">
            <p>Lorem Ipsum Dolor</p>
            <p>Consectetuer Adipiscing</p>
            <p>Sed Diam Nonummy</p>
            <p>Nibh Euismod Tincidunt</p>
            <p>Lorem Ipsum Dolor</p>
          </div>
        </div>
        <div className="footer-title-style">
          <p>SERVICIOS</p>
          <div className="footer-list-style">
            <p>Lorem Ipsum Dolor</p>
            <p>Consectetuer Adipiscing</p>
            <p>Sed Diam Nonummy</p>
            <p>Nibh Euismod Tincidunt</p>
            <p>Lorem Ipsum Dolor</p>
          </div>
        </div>
        <div className="footer-title-style">
          <p>COMPAÑÍA</p>
          <div className="footer-list-style">
            <p>Lorem Ipsum Dolor</p>
            <p>Consectetuer Adipiscing</p>
            <p>Sed Diam Nonummy</p>
            <p>Nibh Euismod Tincidunt</p>
            <p>Lorem Ipsum Dolor</p>
          </div>
        </div>
        <div className="footer-title-style">
          <p>SUSCRIBETE</p>
          <div className="footer-list-style">
            <p>Lorem Ipsum Dolor</p>
            <p>Consectetuer Adipiscing</p>
            <p>Sed Diam Nonummy</p>

          </div>
        </div> */}
      </div>
        <PyselLogo fill="#ffffff"/>
      </div>
  );
};
