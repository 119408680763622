import { Container } from "@mui/material";
import React from "react";
import "./styles.css";

export const HomeServices = () => {
  const homeServices = [
    {
      title: "Circuito Cerrado de Televisión",
      description:
        "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore.",
      item1: "// LOREM IPSUM DOLOR SIT AT",
      item2: "SED DIAM NONUMMY NIBH",
      item3: "TINCIDUNT UT LAOREET DOLORE",
      corner: '01'
    },
    {
      title: "Circuito Cerrado de Televisión1",
      description:
        "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore.",
      item1: "// LOREM IPSUM DOLOR SIT AT",
      item2: "SED DIAM NONUMMY NIBH",
      item3: "TINCIDUNT UT LAOREET DOLORE",
      corner: '02'
    },
    {
      title: "Circuito Cerrado de Televisión2",
      description:
        "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore.",
      item1: "// LOREM IPSUM DOLOR SIT AT",
      item2: "SED DIAM NONUMMY NIBH",
      item3: "TINCIDUNT UT LAOREET DOLORE",
      corner: '03'
    },
  ];

  return (
    <div>
      <div>
        <p className="section-title">//NUESTROS SERVICIOS</p>
      </div>

      <div >
        <p className="section-subtitle">Ofrecemos una amplia <br /> variedad de servicios de TI</p>
      </div>
      <Container>
        <div  id="cards-circuit" style={{ display: 'flex', justifyContent: 'center', paddingBottom: '100px' }}>
          {false && homeServices.map((item, index) => (
            <div className="items-parent-style">

              <div className="corner-style">
                {item.corner}
              </div>
              <div>
                <p className='items-title'>{item.title}</p>
              </div>
              <div>
                <p className='items-description'> {item.description}</p>
              </div>
              <div>
                <p className={index === 0 ? 'items-color-selected' : 'items-color-unSelected'}> {item.item1}</p>
              </div>
              <div>
                <p className={index === 1 ? 'items-color-selected' : 'items-color-unSelected'}> {item.item2}</p>
              </div>
              <div>
                <p className={index === 2 ? 'items-color-selected' : 'items-color-unSelected'}> {item.item3}</p>
              </div>
            </div>
          ))}
        </div>
      </Container>
    </div>)
}