import React from "react";
import { Blog } from "../pages/blog/blog";
import { Home } from "../pages/home/index";
import { About } from "../pages/about/about";
import { Error404 } from "../pages/404page/404";
import { Contact } from "../pages/contact/contact";
import { Services } from "../pages/services/services";
import { Route, Routes } from "react-router-dom";


export const NavRoute = () => {
  return (
    <div>
      <Routes>
        <Route index element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="blog" element={<Blog />} />
        <Route path="services" element={<Services />} />
        <Route path="contact" element={<Contact />} />
        <Route element={Error404} />
      </Routes>
    </div>
  );
};
