import React from "react";
import { Link } from "react-router-dom";
import { HashLink, NavHashLink } from 'react-router-hash-link';
import styled from "styled-components";
export const NavBar = ({margin = 20}) => {
  const navbar = [{
    id:1,
    path:"/",
    name: "Inicio",
  },
  {
    id:2,
    path:"/#services",
    name: "Servicios",
},  
  {
    id:3,
    path:"/#about",
    name: "Nosotros",
  },
  {
    id:4,
    path:"/contact",
    name: "Contactanos",
  },
 
];
const Nav = styled.nav`
font-size: 17px;
  font-family: "Open Sans",sans-serif;
  
  & > .nav-link{
  color:black;
  margin: 20px;
  text-decoration: none;
  justify-content:flex-end;
  @media (max-width:768px){
  margin: 7px;
  margin-bottom: 30px;
  
  
  }

}
`
  return (

    
      <Nav className="nav-style">
        {navbar.map(({ path, name,id }) => {
          return (<HashLink  key={id} className="nav-link" to={path}  >
         
           {name}
         </HashLink>
          )
        })}
        
  
      </Nav>
  );
};
