import React from 'react'

import Carousel from "react-elastic-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import rimage from "../../../../src/images/Home/r-image.png";
import timage from "../../../../src/images/Home/t-image.png";
import bimage from "../../../../src/images/Home/b-image.png";
import gimage from "../../../../src/images/Home/g-image.png";


export const HomeCarousel = () => {
    const info = [
        { id:1, image: rimage, text: "some" },
        { id:2, image: bimage, text: "some" },
        { id:3, image: timage, text: "some" },
        { id:4, image: gimage, text: "some" },
    ];
    return (
        <Carousel
            containerProps={{
                style: {
                    width: "2%",
                    justifyContent: "space-between",
                },
            }}
        >
            {info.map((item) => (
                <div key={item.id} >
                    <img src={item.image}     alt="" />
                </div>
            ))}
        </Carousel>)
}