import React from "react";
import { PyselLogo } from "../../svg/PyselLogo";
import { NavBar } from "../../layouts/NavBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faLinkedinIn,
  faInstagram,
  faFacebookF,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import {
  faSearch,
  faBoxes,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import "./style.css";
import { MainParent } from "./styles";
export const Header = ({ title, text }) => {
const wppLink=() => {
  window.open( "https://wa.link/wwoe1o", '_blank')
}
const mailLink=()=>{
  window.open( "mailto:gerencia@pysel.com.co?subject=Servicios Pysel &body=Hola, estoy interesado en los productos y servicios ofrecidos por Pysel, quisiera mayor información. Muchas gracias.",'_blank')
}

  return (
    <MainParent className="main-parent">
      <div className="main-parent__container">
        <div className="main-parent__container__topheader">
          <div className="main-parent__container__topheader__icons">
           {false && <a className="item">  <FontAwesomeIcon icon={faTwitter} /></a>}
           
            <a className="item" target="_blank" href="https://www.facebook.com/pyselsas">
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
            <a className="item" target="_blank" href="https://www.linkedin.com/company/pyselsas">
              <FontAwesomeIcon icon={faLinkedinIn} />
            </a>
            <a className="item" target="_blank" href="https://instagram.com/pyselsas?utm_medium=copy_link">
              <FontAwesomeIcon  icon={faInstagram} />
            </a>
          </div>
          <div className="main-parent__container__topheader__text">


            <div onClick={wppLink} className="main-parent__container__topheader__text__item">
              <FontAwesomeIcon icon={faWhatsapp}  />
              <p>+57 3183715550</p>
            </div>

            <div onClick={mailLink} className="main-parent__container__topheader__text__item">
                <FontAwesomeIcon icon={faEnvelope} />
              <p>gerencia@pysel.com.co</p>
            </div>


          </div>
        </div>
      </div>
      <div className="middle-header">
        <div
          style={{
            marginRight: "5vw",
          }}
        >
          <PyselLogo />
        </div>
        <div className="middle-header-links">
          <NavBar />
        </div>
      </div>
    </MainParent>
  );
};
