import styled from "styled-components";
import banner from "../../images/Home/HomeImage.jpg"

export const WrappeContact = styled.div`
    background-image: url(${banner});
    overflow-y: hidden;
    background-repeat:no-repeat;
    background-size: cover;
    background-position: center;
    align-items: center;
    justify-content: center;
    align-content: center;
    align-items: center;
  .contact__container {
    min-height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 100;
    &-title {
      width: 100%;
      z-index: 2000;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      text-align: center;
      top: 0px;
      color: white;
      font-size: 1.5rem;
    }
    &-title2 {
      font-family: "Georgia";
      display: flex;
      align-items: flex-start;
      justify-content: center;
      color: white;
      font-size: 1.5rem;
    }
    &__form {
      -webkit-box-pack: center;
    justify-content: center;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    flex-direction: column;
    padding: 2.95rem  1.5rem;
    margin:auto; 
    overflow-y: hidden;
    flex: 1 1 0%; 

    border-radius: 10px;
    top: 30%;
    z-index: 98;
    font-family: Helvetica;
      &__div {
        margin-bottom: 20px;
        & > .error{
          font-weight: bold;
          font-size: 14px;
          color: #ff9eac;
          
        }
        &-icon{
          color:white;
        }
        &-label {
          display: block;
          font-weight: 600;
          font-size: 16px;
          margin-bottom: 5px;
          color: white;
        }
        &-input {
          font-family: "Open Sans", sans-serif;
          width: 100%;
          border-radius: 5px;
          border: 2px solid #e2e2e2;
          font-size: 18px;
          padding: 10px;
          margin-bottom: 5px;
          min-width: 32vw;
          color: #1f1f1f;
        }
      }
      &-labeltext{
        font-weight: 600;
    font-size: 18px;
    margin-bottom: 5px;
    color: white;
      }
      &-textarea {
        font-family: "Open Sans", sans-serif;
        width: 40%;
        height: 200px;
        border-radius: 5px;
        border: 2px solid #e2e2e2;
        font-size: 18px;
        padding: 10px;
        margin-bottom: 5px;
        
      }
      &-button {
        display: block;
        background: #0085ff;
        font-weight: 600;
        font-family: "Open Sans", sans-serif;
        border: none;
        cursor: pointer;
        padding: 10px;
        border-radius: 5px;
        color: #fff;
        font-size: 16px;
        transition: 0.3s ease all;
        
      }
      & > .exito{
        font-family: "Open Sans", sans-serif;
        color: greenyellow;
        font-weight: bold;
        font-size: 18px;
          }
    }
  }
  @media (max-width: 768px) {
    width: 100%;
    .contact__container {
      &-title2 {
        text-align: center;
      font-size: 25px;
      }
      &__form-textarea{
        width: 85%;
      }
    }
  }
`;
