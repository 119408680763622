import styled from "styled-components";

export const MainParent = styled.div`
  .main-parent {
    &__container {
      background-color: #141d38;
      &__topheader {
        display: flex;
        background-color: #141d38;
        height: 45px;
        justify-content: space-between;
        &__icons {
          display: flex;
          flex-direction: row;
          align-items: center;
         
          & > .item {
            padding: 13px;
            color:white;
            font-size: 20px;
            cursor: pointer;
            
          }
        }
        &__text {
          color: #ffffff;
          display: flex;
          font-size: 14px;
          align-items: center;
          flex-direction: row;
          &__item {
            display: grid;
            align-content: center;
            justify-content: center;
            align-items: center;
            justify-items: center;
            grid-template-columns: 0.4fr 1fr 0.1fr;
            grid-template-rows: auto auto;
         
            cursor: pointer;
            
          }
        }
      }
    }
  }
  @media(max-width: 425px){
    
    
    .main-parent__container__topheader{ 
    &__icons > .item{
      border-left: none;
    }
    
    &__text{
      
display: block;
  border-top: 1px solid rgb(255 245 0 / 10%);
  &__item{
    border-bottom: 1px solid rgb(255 245 0 / 10%);
  }
    }
 
  }
 

  @media (max-width: 768px) {
    .main-parent {
      width: 100%;
      &__container {
        width: 100%;
        height: 100%;
        background-color: #141d38;
        &__topheader {
          width: 100%;
          display: flex;

          &__icons {
            justify-content: space-around;
            
          }
        }
      }
    }
  }
  @media (max-width: 700px) {
    .main-parent__container__topheader {
      flex-direction: column;
      height: 100%;
    }
  }
}
`;
