import React from "react";
import { Container } from "@mui/material";
import leftSpiral from "../../../../src/images/Home/leftSpiral.png";
import rightSpiral from "../../../../src/images/Home/rightSpiral.png";
import workerHomeImage from "../../../../src/images/Home/worker.png";
import playHomeIcon from "../../../../src/images/Home/playHomeIcon.png";
import { WrappeAboutUs } from "./styles";

export const AboutUs = () => {
  return (
    <WrappeAboutUs className="about-us__container" id="about">
      <Container>
        <img
          src={leftSpiral}
          alt="spiralLeft"
          className="about-us__container__img1"
        />
        <div className="about-us__container__img">
          <img
            src={workerHomeImage}
            alt=""
            className="about-us__container__img2"
          />
        </div>

        <div style={{ width: "90%" }}>
          <div>
            <p className="upper-worker-text">ACERCA DE NOSOTROS</p>
          </div>

          <div>
            <p className="about-us__container-middleBot-worker-text">
              PYSEL SAS es una empresa dedicada al diseño y construcción de
              instalaciones eléctricas residenciales e industriales. Al diseño y
              desarrollo web y mobile, desarrollo de software y auditoria de
              código de software. Fundada en el año 2021 con la intención de
              revolucionar el mercado de la vivienda mediante recursos humanos y
              tecnológicos que garantizan no solo la calidad de nuestra labor
              sino también un servicio integral a todos nuestros clientes.
            </p>
            <p className="about-us__container-middleBot-worker-text">
              Nuestro personal cuenta con 9 años de experiencia en la rama
              eléctrica y 5 años en la rama tecnológica, tiempo en el que se ha
              logrado consolidar una experiencia que permite entender las
              necesidades y satisfacer los requerimientos de los clientes y
              usuarios finales, garantizando resultados óptimos en tiempo récord
              y con los mejores precios del mercado.
            </p>
            <p className="about-us__container-middleBot-worker-text">

            Nuestra labor es ofrecer una variedad de productos y servicios, que
            van desde la INTERVENTORÍA, MONTAJES, DISEÑOS, ESTUDIOS, ASESORÍAS,
            SOLUCIONES ELÉCTRICAS Y TECNOLOGICAS, amplia variedad que componen
            nuestro portafolio. A continuación, presentamos nuestro PORTAFOLIO
            DE SERVICIOS EN INGENIERÍA ELÉCTRICA y DESARROLLO Y GESTION DE
            SOFTWARE.
            </p>
          </div>

          {/*  <div className="video-button">
                    <img src={playHomeIcon} alt="" width="60" height="60" />
                    <p>VIDEO SHOWCASE</p>
                </div> */}
          {/*  <img src={rightSpiral} alt="spiralRight" width="18%" style={{position: 'absolute', right: 5, top: 1700}} /> */}
        </div>
      </Container>
    </WrappeAboutUs>
  );
};
