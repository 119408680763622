import React from "react";
import { Header } from "../../components/header/Header";

export const Blog = () => {
  return (
    <div>
      <div>
        <Header title={"Blog"} text />
      </div>
    </div>
  );
};
