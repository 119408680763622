import React from 'react'
import { Grid, Box, Paper, Container } from '@mui/material'

import img1 from  '../../../../src/images/Home/img1.jpeg'
import img2 from '../../../../src/images/Home/img2.jpeg'
import img4 from '../../../../src/images/Home/img3.jpeg'
import img3 from '../../../../src/images/Home/img4.jpeg'
import leftSpiral from "../../../../src/images/Home/bg-art2-home4.png";
import rightSpiral from "../../../../src/images/Home/bg-art3-home4.png";
import { WrapperTiServies } from './styles'


export const TiServices = () => {
    const textArray = [
        { 'img': img1,id:1, title: 'Construcción de instalaciones eléctricas residenciales e industriales', description: 'Instalaciones eléctricas residenciales - Circuito cerrado de televisión - Sistemas de detección de incendio - Infraestructura RITEL y TDT - Citofonía - Alumbrado publico - Infraestructura aérea y terrestre de red de distribución en media y baja tensión.'},
        { 'img': img2,id:2, title: 'Diseño de instalaciones eléctricas residenciales e industriales.', description: ' Diseñamos instalaciones eléctricas residenciales - Circuito cerrado de televisión - Sistemas de detección de incendio - Infraestructura RITEL y TDT - Citofonía- Alumbrado publico - Red de distribución en media y baja tensión.'},
        { 'img': img3,id:3, title: 'Asesoría, Diagnostico y Tramites para la Certificación de Uso final, RETIE.', description: ' Solicitudes, Trámites y Reclamaciones ante Empresas de Energía. - Estudios de Análisis de red y/o Calidad de Energía. - Consultoría, diagnóstico y soluciones integrales en el área eléctrica. - Diseño e Implementación del Sistema de Protección contra Rayos SIPRA. - Corrección del factor de potencia (Eliminación por penalización debido al consumo o suministro de energíareactiva CREG 015 DE 2018). - Planeación, Diseño, Ejecución, Control, Desarrollo y Mejoramiento de Proyectos Eléctricos.' },
        { 'img': img4,id:4, title: ' Interventoría y Soporte Técnico', description: ' Contamos con una estructura organizacional y personal para el desarrollo de la gestión integral de proyectos, interventorías administrativas y técnicas, supervisión de obra y/o soporte técnico en sitio.'}]
         return (
        <WrapperTiServies className="ti-services" id='services'>
            <Container className="ti-services__container">
                <img src={leftSpiral} alt="spiralLeft" className="ti-services__container-img" />

                    <p className='ti-services__container-subtitle'>//NUESTROS SERVICIOS</p>

                <div className="ti-services__container__title">
                    <p className='ti-services__container__title-text' >Ofrecemos una amplia <br /> variedad de servicios de TI</p>
                    
                        <p className="read-more-services-style"  >SERVICIOS</p>
                    
                </div>

                    <Grid 
                        container
                  spacing={5}
                  className="ti-services-grid-container"
              >
         {textArray.map((item,index) => (
             <Grid item xs={12} sm={6} md={6} className='ti-services__container__grid' key={item.id}>
                 <Grid item xs={12} sm={12} md={12}  className='ti-services__container__grid__items'>
                     <img className='ti-services__container__grid__items-img'src={item.img}     alt='TiIcons'  />
                 </Grid>
                 <Grid item xs={12} sm={12} md={12} style={{ display: 'flex', flexDirection: 'column' }}>
                     <p style={{textAlign:'center',font:'font-family: "Open Sans",sans-serif',fontSize:"25px"}}>{item.title}</p>
                     {item.description.split(' - ').map((item3, index) => {return <p key={item.title + index} style={{ margin: 0, font:'font-family: "Open Sans",sans-serif',letterSpacing: '-0.38px',color:'#6D6D6D',display: 'flex', textAlign: 'center', flexDirection: 'column',fontSize:"19px"}}>- {item3}</p>})}
                 </Grid>
             </Grid>

         ))}
     </Grid>
     <img src={rightSpiral} alt="spiralRight" width="5%" style={{ position: 'absolute', right: 5, bottom: 0 }} />






            </Container>
        </WrapperTiServies>
    )
}
