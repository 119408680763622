import React, { useState } from "react";
import { Header } from "../../components/header/Header";
import { Formik, Form } from "formik";
import { WrappeContact } from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faUser, faPhone } from "@fortawesome/free-solid-svg-icons";
import { useForm} from '@formspree/react';
import { showToast } from "../../utils/show-message";


export const Contact = () => {
  const [state, handleSubmit] = useForm("moqykgrz");
  const [formularioEnviado, cambiarFormularioEnviado] = useState(false);
  if(state.succeeded){
  showToast({
    id:"email-success",
    message:"Email enviado correctamente",
    type:"success",
    });
  }
   if (state.errors.length > 0) {
  showToast({
    id: "email-error",
    message: "Ha ocurrido un error al enviar el correo",
    type: "error",
    });
  }

  return (
    <>
      <Header title={"Contácto"} text />
      <WrappeContact className="contact__container">
        <p className="contact__container-title">¡Contáctanos! </p>
        <p className="contact__container-title2">
          {" "}
          Diligencia con tus datos y nos pondrémos en contacto contigo a la
          brevedad posible.
        </p>
          <p className="contact__container-title2">
            ¡Pysel te espera!
            </p>
        <Formik
          initialValues={{
            userName: "",
            email: "",
            message: "",
            whatsapp: "",
          }}
          validate={(valores) => {
            let errores = {};

            //validacion nombre
            if (!valores.userName) {
              errores.userName = 'El nombre es requerido';
            } else if (!/^[a-zA-ZÀ-ÿ\s]{1,40}$/.test(valores.userName)) {
              errores.userName = "El nombre debe contener solo letras";
            }

            //validacion email
            if (!valores.email) {
              errores.email = "El correo es requerido";
            } else if (!/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test( valores.email)            ) {
              errores.email ="El correo puede contener letras,puntos,guiones";
            }

            //validacion whastapp
            if ( !valores.whatsapp) {
              errores.whatsapp = 'El whatsapp es requerido';
            } else if (/^[a-zA-ZÀ-ÿ\s]{1,40}$/.test(valores.whatsapp)) {
              errores.whatsapp = "El whatsapp debe contener solo numeros";
            }

            return errores;
          }}
          onSubmit={(valores, { resetForm }) => {
            cambiarFormularioEnviado(true);
            console.log(valores);
            resetForm();
            setTimeout(() => cambiarFormularioEnviado(false), 5000);
            
          }}
        >
          {({ values,handleChange, handleBlur, errors, touched}) => (
            <Form className="contact__container__form" onSubmit={handleSubmit} >
              {console.log(values,errors)}
              <div className="contact__container__form__div">
                <label
                  className="contact__container__form__div-label"
                  htmlFor="Nombre "
                  >
                  <FontAwesomeIcon className="contact__container__form__div-icon" icon={faUser} />{' '}
                  Nombre: 
                </label>

                <input
                  className="contact__container__form__div-input"
                  type="text"
                  id="userName"
                  name="userName"
                  placeholder="Jhon Doe"
                  value={values.userName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              {touched.userName && errors.userName && <div className="error">{errors.userName}</div>}
              </div>
              <div className="contact__container__form__div">
              
                <label
                  className="contact__container__form__div-label"
                  htmlFor="Correo Electrónico"
                >
                    <FontAwesomeIcon className="contact__container__form__div-icon" icon={faEnvelope} />{' '}
                  Correo Electrónico:
                </label>
                <input
                  className="contact__container__form__div-input"
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Correo@correo.com"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              {touched.email && errors.email &&  <div className="error">{errors.email}</div>}
              </div>
              <div className="contact__container__form__div">
                
                <label
                  className="contact__container__form__div-label"
                  htmlFor="whatsapp"
                >
                  <FontAwesomeIcon className="contact__container__form__div-icon" icon={faPhone} />{' '}
                  WhatsApp:
                </label>
                <input
                  maxlength="10"
                  className="contact__container__form__div-input"
                  id="whatsapp"
                  name="whatsapp"
                  type="text"
                  placeholder="3216812xxx"
                  value={values.whatsapp}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              {touched.whatsapp && errors.whatsapp && <div className="error">{errors.whatsapp}</div>}
              </div>
              <label className="contact__container__form-labeltext"  htmlFor="message">Mensaje:</label>
              <textarea
                className="contact__container__form-textarea"
                id="message"
                name="message"
                onChange={handleChange}
                onBlur={handleBlur} />

              <button className="contact__container__form-button" type="submit">¡Cotiza con nosotros!</button>
              {formularioEnviado && <p className="exito">Formulario enviado con exito!</p>}
            </Form>
          )}
        </Formik>
      </WrappeContact>
    </>
  );
};

export default Contact;
