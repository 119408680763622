import "./style.css";
import React from "react";
import { Header } from "../../components/header/Header";
import { Footer } from "../../components/footer/Footer";



import HomeMainImage from "../../../src/images/Home/HomeImage.jpg";
import { HomeCarousel } from "./carousel/Carousel";
import { AboutUs } from "./aboutUs/AboutUs";
import { HomeServices } from "./services/Services";
import { CompaniesSection } from "./companies/Companies";
import { TiServices } from "./tiServices/TiServices";
import { Container, Grid } from "@mui/material";
import { Projects } from "./projects/Projects";
import styled from "styled-components";


export const Home = () => {

  const HomeContainer = styled.div`
@media (max-width:768px){
  width: 100%;

}
`

  return (
    <HomeContainer>
      <Header />
      <div className="home-main-image">
        <img src={HomeMainImage} alt="" />
        <div className="text-style">
            <p className="above-subtitle">Sean todos muy bievenidos a</p>
            <h1 className="main-text-title">PROYECTOS Y <br />SOLUCIONES ELÉTRICAS <br/> Y TECNOLOGICAS</h1>
            <p className="subtitle">
              Protegemos el medio ambiente en cada uno de nuestros<br /> procesos
              relacionados.
            </p>
            {false && <button className="read-more-style">LEER MÁS</button>}

        </div>
      </div>
      <div style={{ marginTop: 59 }}>
        <HomeCarousel />
        <AboutUs />
      </div>
      <div className="services">
        <HomeServices />
      </div>
      {false && <CompaniesSection />}
      <TiServices />
      {false && <Projects />}
      <Footer />
    </HomeContainer >
  );
};
